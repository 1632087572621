import { useState, useEffect } from 'react';

export default function useWindowDimensions() {
  const hasWindow = typeof window !== 'undefined';

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [hasWindow]);

  const isMobile = windowDimensions.width < 768;
  const isTablet =
    windowDimensions.width >= 768 && windowDimensions.width < 1024;
  const isTabletUnder = windowDimensions.width < 1024;
  const isDesktop = windowDimensions.width >= 1024;
  const isUnderDesktopLarge = windowDimensions.width < 1280;

  return {
    windowDimensions,
    isDesktop,
    isTablet,
    isMobile,
    isTabletUnder,
    isUnderDesktopLarge,
  };
}
