import { getLocaleUrl } from '@/helpers/index';
import useWindowDimensions from 'hooks/useWindowDimensions';
import _, { cloneDeep, uniqueId } from 'lodash';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { memo, useCallback, useMemo } from 'react';
import { ChevronRight } from 'react-feather';
import { SwiperSlide } from 'swiper/react';

const Article = dynamic(() => import('../article/Article'), { ssr: false });
const SwiperCarousel = dynamic(() => import('./SwiperCarousel'), {
  ssr: false,
});

const LATEST_ARTICLES = 'Latest Articles';

function ArticlesCarousel({
  name = '',
  viewAllSlug = '',
  articles = [],
  onAddFavourite,
  isGetByCategory = false,
}) {
  const { locale } = useRouter();
  const { isMobile, isDesktop, isTablet, isTabletUnder } =
    useWindowDimensions();

  const listArticles = useMemo(() => {
    let array = [];
    const cloneArray = cloneDeep(articles);

    if (name === LATEST_ARTICLES) {
      if (isDesktop) {
        array = [
          ...[_.slice(cloneArray, 0, 3)],
          ...[_.slice(cloneArray, 3, 6)],
          ...[_.slice(cloneArray, 6, 10)],
        ];
      } else if (isTablet) {
        array = _.chunk(cloneArray, 3);
      } else array = _.chunk(cloneArray, 2);
    } else {
      if (isMobile) array = _.chunk(cloneArray, 2);
      if (isTablet) array = _.chunk(cloneArray, 3);
      if (isDesktop) array = _.chunk(cloneArray, 4);
    }

    return array;
  }, [articles, name, isMobile, isDesktop, isTablet]);

  const checkConditionHaveBigArticle = useCallback(
    (totalArticles, numberOfSlide, position) => {
      if (
        totalArticles >= 3 &&
        numberOfSlide <= 1 &&
        position === 0 &&
        name === LATEST_ARTICLES &&
        isDesktop
      ) {
        return true;
      }

      return false;
    },
    [name, isDesktop]
  );

  return (
    <div className="articles-block">
      <div className="articles-block__title">
        <h1 className="title">{name}</h1>
        {viewAllSlug && articles.length > 4 && (
          <Link
            href={getLocaleUrl(
              locale,
              isGetByCategory ? `category/${name}` : `tag/${name}`
            )}
          >
            <a>
              <div className="view-all">
                View All <ChevronRight />
              </div>
            </a>
          </Link>
        )}
      </div>

      {listArticles.length > 0 && (
        <div className="articles-block__carousel">
          <SwiperCarousel
            spaceBetween={32}
            slidesPerView={1}
            isShowNavigation={true}
            totalItems={listArticles.length}
          >
            {_.map(listArticles, (articleSlide = [], slideIndex) => (
              <SwiperSlide key={uniqueId()}>
                <div className="article-slide">
                  {_.map(articleSlide, (article, index) => (
                    <Article
                      key={index}
                      isArticleBig={checkConditionHaveBigArticle(
                        articleSlide?.length,
                        slideIndex,
                        index
                      )}
                      article={article}
                      onAddFavourite={onAddFavourite}
                    />
                  ))}
                </div>
              </SwiperSlide>
            ))}
          </SwiperCarousel>
        </div>
      )}
    </div>
  );
}

export default memo(ArticlesCarousel);
