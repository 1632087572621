import { get } from 'lodash';
import dynamic from 'next/dynamic';
import { memo, useEffect, useRef, useState } from 'react';
import { getArticlesByTagOrCategory } from 'services';
import ArticlesCarousel from '../carousel/ArticlesCarousel';
import cookie from 'js-cookie';

const ArticleCarouselLoading = dynamic(() =>
  import('@/components/loading/ArticleCarouselLoading')
);

function ArticleBlock({
  locale,
  isGetByCategory = false,
  name = '',
  mainTitle = '',
  onAddFavourite = () => {},
}) {
  const articleBlockRef = useRef();
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      const isVisible = entry.isIntersecting;

      if (isVisible && name && !articles.length) {
        const handleGetArticles = async () => {
          const model = {
            name,
            isCategory: isGetByCategory,
            locale,
            page: 1,
            limit: 10,
            user_id: cookie.get('user_id'),
          };

          try {
            const result = await getArticlesByTagOrCategory(model);
            if (get(result, 'data.status') == 1) {
              setArticles(get(result, 'data.articles', []));
            }
          } catch (error) {
            console.log(error);
          }
        };

        handleGetArticles();
        observer.disconnect();
      }
    });

    articleBlockRef.current &&
      observer?.observe(articleBlockRef.current, {
        root: null, // Use the viewport as the root
        rootMargin: '0px',
        threshold: 1.0,
      });

    return () => observer.disconnect();
  }, [name, articles]);

  return (
    <section
      ref={articleBlockRef}
      id={`article-${name}-block`}
      className="articles-section"
    >
      <ArticlesCarousel
        name={mainTitle}
        viewAllSlug={name}
        articles={articles}
        onAddFavourite={onAddFavourite}
        isGetByCategory={isGetByCategory}
      />

      {articles.length === 0 && (
        <ArticleCarouselLoading
          isLatestArticles={mainTitle === 'Latest Articles'}
        />
      )}
    </section>
  );
}

export default memo(ArticleBlock);
